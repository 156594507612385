import React from 'react';
import './App.css';

const Component__body = () => {
  return (
    <div className="Component__body">
      <div className="element-fb8e159b-4762-49b5-b955-6d3de9bb68ca"><p className="element-b464d8d0-5036-477c-b5cf-3917fd57fada">FAHEEM AHMAD
</p>
      <p className="element-4cd9b5af-ab1b-45cf-a8bd-3c9729ed6233">SENIOR SOFTWARE ENGINEER
Techlogix / 2018 - 2024*</p>
      <button className="element-f2a7722a-6b98-4540-a212-35d3d854e432"></button></div>
      <div className="element-9cd16585-2f85-4eab-85f5-4f762784166b"></div>
      <div className="element-51787ab7-1c8d-4254-8ef6-d61ab4dd5461"><p className="element-a9f42994-d26a-42a3-bf17-aa3d201a90b9">FRONTEND
</p>
      <div className="element-2cdd6de1-f72b-4c9f-855f-fa465d745e9a"><p className="element-5a821c34-2f19-4a04-a5d2-67f867da3a9e">DEVELOPER</p>
      <p className="element-39f17bc7-22d4-438a-81d8-b0713b998396">"I’m a passionate developer from Lahore, Pakistan, specializing in crafting interactive and engaging digital experiences for the web and mobile. Whether it's building dynamic websites or seamless mobile apps, I bring ideas to life with a blend of creativity and technical expertise."</p></div></div>
      <div className="element-e4b66705-7ab8-4b7e-a082-5b766a43e551"></div>
    </div>
  );
};

function App() {
  return (
    <div className="App">
      <Component__body />
    </div>
  );
}

export default App;